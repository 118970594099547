<template>
  <div class="daddy">
    <h1>Daddy Koopah</h1>
    <router-link class="close" @click="hide" to="/">hide</router-link>
  </div>

  <div class="outer">
    <div class="menu-holder">
      <div class="test"><a href="#" >TP ON</a>
      </div>
      <div class="test"><a href="#" @click="getOutfits">Outfits</a>
      </div>
      <div class="test"><a href="#" @click="getPoses">Poses</a>
      </div>
      <div class="test"><a href="#">Adult</a>
      </div>
      <div class="test"><a href="#">Animations</a>
      </div>
      <div class="test"><a href="#">Other</a>
      </div>
    </div>
    <div :class="tab===0?'right-holder':'right-holder-flat'">
      <div v-if="tab === 1 && sitingV" class="right-el">
        <a href="#" @click="unsit()">
          unsit
        </a>
      </div>
      <div class="right-el" :key="element" v-for="element in elements">
        <a v-if="tab === 0" href="#" @click="putOn(element)">
          {{element}}
        </a>
        <a v-if="tab === 1" href="#" @click="sit(element)">
          {{ element.val }}
        </a>
      </div>
    </div>
  </div>

  <div class="info">
    <div v-if="user">
      <p>Daddy {{user.username}} gives you a sweet kiss</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {

name: "MainMenu",
  data() {
    return {
      timer : null,
      data: null,
      dataHud: null,
      user: null,
      elements: null,
      hud: null,
      tab: 0,
      siting: false
    }
  },
  computed:{
    UURL() {
      // `this` points to the component instance
      return "https://sl.koopahtmaniac.com/relay";
    },
    sitingV() {
      return this.siting;
    }
  },
  methods: {
    async lure() {

    },
    async teleport() {

    },

    async callApi(data) {
      const response = await axios({
        method: 'post',
        url: this.UURL,
        data: data
      })
      let da = response?.data
      console.log(response?.data);
      if (da && da.length > 0) {
        return da;
      }
      return -1;
    },

    async sit(pose) {
      await this.callApi({
        url: this.user.url,
        method: `sit`,
        pose: pose.key
      })
      this.siting = true
    },

    async unsit() {
      await this.callApi({
        url: this.user.url,
        method: `unsit`,
      })
      this.siting = false
    },

    async getOutfits() {
      const resp = await this.callApi({
        url: this.user.url,
        method: "getOutfits"
      });
      if(resp !== -1) {
        this.tab = 0;
        this.elements = resp;
      }
    },

    async getPoses() {
      const resp = await this.callApi({
        url: this.user.url,
        method: "getPoses"
      });
      if(resp !== -1) {
        this.tab = 1;

        let list = [];
        for(let el of resp) {
          console.log(el)
          let s = atob(el)
          console.log(s)
          let dd = s.split(',')
          let key = dd[0]
          let val = atob(dd[1])

          list.push({ key: key, val: val })
        }

        this.elements = list;

      }
    },

    async putOn(outfit) {
      const resp = await this.callApi({
        url: this.user.url,
        method: "equipOutfit",
        outfit: encodeURIComponent(outfit)
      })
      return resp;
    },

    async hide() {
      const resp = await this.callApi({
        url: this.hud.url,
        method: "close",
      })
      return resp;
    },

    async loadData() {
      const vm = this
      await axios
          .get('https://sl.koopahtmaniac.com/hud')
          .then(response => {

            let data = response?.data
            if (data && data.length > 0) {
              for (const user of data) {
                if(user.sc === "Koopah Main HUD") {
                  vm.user = user
                }
              }
            }
            vm.data = response?.data
          });

      await axios
          .get('https://sl.koopahtmaniac.com/hud')
          .then(response => {

            let data = response?.data
            if (data && data.length > 0) {
              for (const hud of data) {
                if(hud.sc === "Koopah HUD") {
                  vm.hud = hud
                }
              }
            }
            vm.dataHud = response?.data
          });
    }
  },
  mounted() {
    this.loadData();
  },
  watch : {
    endDate : {
      immediate : true,
      handler(){
        if(this.timer){
          clearInterval(this.timer)
        }
        this.timer = setInterval(()=>{
          this.loadData()
        }, 1000)
      }
    }
  },
  beforeUnmount(){
    clearInterval(this.timer)
  }
}
</script>

<style>
body {
  width:512px;
  height: 512px;
  background: #303234;
  color: #7F8493;
  font-family: Arial;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

html {
  background: white;
  margin:0;
}


</style>

<style scoped>
#app {
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.test {
  border-radius: 6px;
  background: #303234;
  box-shadow:  4px 4px 8px #00000066,
  -4px -4px 8px #49494966;
  stroke: #272829;
  stroke-width: 1pt;
  padding: 2px 15px;
  line-height: 30px;
  color: #C8A2C8;
  margin: 5px;
  display: flex;
  text-align: center;
  justify-content: center;
}

.right-el {
  border-radius: 6px;
  background: #303234;
  box-shadow:  4px 4px 8px #00000066,
  -4px -4px 8px #49494966;
  stroke: #272829;
  stroke-width: 1pt;
  padding: 0px 0px;
  line-height: 30px;
  box-sizing: border-box;
  flex-shrink: 1;
  color: #C8A2C8;
  margin: 5px;
  display: flex;
  text-align: center;
  justify-content: center;
}

.right-el a {
  text-align: center;
  justify-content: center;
  text-decoration: none;
  color: #C8A2C8;
}

.menu-holder {
  border-radius: 6px;
  background: #303234;
  box-shadow:  4px 4px 8px #49494966,
  -4px -4px 8px #00000066;
  stroke: #272829;
  stroke-width: 1pt;
  padding: 10px 10px;
  line-height: 30px;
  color: #C8A2C8;
  display: flex;
  flex-direction: column;
  margin: 0 0 0 0px;
}

.test a {
  text-decoration: none;
  color: #C8A2C8;
  display: inline-block;
  height: 30px;
  text-align: center;
}

.outer {
  justify-content: left;
  align-items: center;
  float: none;
  display: flex;
  flex-grow: 0;
  overflow-y: hidden;
  padding: 10px 10px 10px 20px;
  height: 280px;
}

.daddy {
  border-radius: 6px;
  background: #303234;
  box-shadow:  4px 4px 8px #49494966,
  -4px -4px 8px #00000066;
  stroke: #272829;
  stroke-width: 1pt;
  padding: 10px 10px;
  line-height: 30px;
  color: #C8A2C8;
  margin: 10px 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 14px;
}

.info {
  border-radius: 6px;
  background: #303234;
  box-shadow:  4px 4px 8px #49494966,
  -4px -4px 8px #00000066;
  stroke: #272829;
  stroke-width: 1pt;
  padding: 10px 10px;
  line-height: 30px;
  color: #C8A2C8;
  margin: 10px 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 16px;
}

.close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  font-size: 30px;
  text-decoration: none;
  color: #C8A2C8;
  box-shadow:  4px 4px 8px #00000066,
  -4px -4px 8px #49494966;
  border-radius: 20px;
  width: 90px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.right-holder {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  margin-left: 15px;
  margin-right: 0;
  padding-right: 5px;
  box-sizing: border-box;
}

.right-holder-flat {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  margin-left: 15px;
  margin-right: 0;
  padding-right: 5px;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #49494966;
  border-radius: 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00000066;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000000CC;
}
</style>
