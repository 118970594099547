<template>
  <div class="holder">
    <RouterLink class="button" to="MainMenu" @click="openMenu">Daddy</RouterLink>
  </div>
</template>

<script>

import axios from "axios";
export default {
name: "Base",
  data() {
    return {
      hud: null,
    }
  },


  methods: {

    async callHUD(data) {
      const response = await axios({
        method: 'post',
        url: "https://sl.koopahtmaniac.com/relay",
        data: data
      })
      let da = response?.data
      console.log(response?.data);
      if (da && da.length > 0) {
        return da;
      }
      return -1;
    },

    async openMenu() {
      await this.callHUD({
        url: this.hud.url,
        method: "open"
      })
    },

    async loadData() {
      const vm = this

      axios
        .get('https://sl.koopahtmaniac.com/hud')
        .then(response => {

          let data = response?.data
          if (data && data.length > 0) {
            for (const hud of data) {
              if(hud.sc === "Koopah HUD") {
                vm.hud = hud
              }
            }
          }
          vm.dataHud = response?.data
      });
    }
  },
  mounted() {
    this.loadData();
  },
  watch : {
    endDate : {
      immediate : true,
      handler(){
        if(this.timer){
          clearInterval(this.timer)
        }
        this.timer = setInterval(()=>{
          this.loadData()
        }, 1000)
      }
    }
  },
  beforeUnmount(){
    clearInterval(this.timer)
  }
}
</script>

<style>
body {
  width: 512px;
  height: 512px;
  background: transparent;
  color: #7F8493;
  font-family: Arial;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  justify-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

html {
  background: transparent;
  margin:0;
  padding:0;
}
</style>

<style scoped>



.holder {
  border-radius: 6px;
  background: #303234;
  box-shadow:  20px 20px 40px #00000066,
  -20px -20px 40px #49494966;
  stroke: #272829;
  stroke-width: 10pt;
  padding: 0;
  line-height: 400px;
  color: #C8A2C8;
  margin: auto;
  display: flex;
  text-align: center;
  justify-content: center;
  width: 400px;
  height: 400px;
  box-sizing: border-box;
  justify-items: center;
  align-items: center;
}

.button {
  border-radius: 6px;
  background: #303234;
  box-shadow:  10px 10px 20px #00000066,
  -10px -10px 20px #49494966;
  stroke: #272829;
  stroke-width: 1pt;
  line-height: 400px;
  height: 400px;
  width: 400px;
  color: #C8A2C8;
  display: flex;
  flex-direction: column;
  margin: auto;
  box-sizing: border-box;
  align-items: center;
  justify-items: center;
  padding: 10px;
}

.button {
  text-decoration: none;
  color: #C8A2C8;
  display: inline-block;
  text-align: center;
  font-size: 100px;
}

</style>
