import { createRouter, createWebHistory } from 'vue-router'
import Base from "../views/Base";
import MainMenu from "../views/MainMenu";

const routes = [
  {
    path: '/',
    name: 'Base',
    component: Base
  },

  {
    path: '/MainMenu',
    name: 'MainMenu',
    component: MainMenu
  },

  {
    path: '/MainMenu',
    name: 'MainMenu',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MainMenu.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
